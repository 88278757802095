import React, { ReactNode } from 'react';

interface JumbotronProps {
    children?: ReactNode;
}

const Jumbotron = ({ children }: JumbotronProps) => (
    <div className='jumbotron'>
        {children}
    </div>
);

export default Jumbotron;
