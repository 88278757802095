import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, ApplicationState } from '../../store';
import Footer from './Footer';
import Header from './Header';
import Snackbars from '../common/Snackbars';

interface LayoutProps {
    children?: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const dispatch = useDispatch();
    const snackbars = useSelector((state: ApplicationState) => state.snackbars.snackbars);

    return (
        <div className='page'>
            <Header />
            <main id='main' tabIndex={-1}>
                {children}
            </main>
            <Snackbars snackbars={snackbars} removeSnackbar={x => dispatch(actionCreators.removeSnackbar(x))} />
            <Footer />
        </div>
    );
};

export default Layout;
