import { Reducer } from 'redux';
import { Language } from '../enums';

export interface State {
    language: Language;
}

interface SetLanguageAction {
    type: 'SET_LANGUAGE';
    language: Language;
}

type KnownAction = SetLanguageAction;

export const actionCreators = {
    setLanguage: (language: Language): SetLanguageAction => ({ type: 'SET_LANGUAGE', language })
};

export const reducer: Reducer<State, KnownAction> = (state, action) => {
    if (state === undefined) {
        const storedLanguage = window.localStorage.getItem('language');

        return { language: storedLanguage ? Number(storedLanguage) : Language.En };
    }

    switch (action.type) {
        case 'SET_LANGUAGE':
            return { language: action.language };
        default:
            return state;
    }
};
