import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';

interface LoaderProps {
    children?: ReactNode
    dark?: boolean;
    loading?: boolean;
    margin?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    transparent?: boolean;
}

const Loader = ({ children, dark, loading, margin, marginBottom, marginLeft, marginRight, marginTop, transparent }: LoaderProps) => {
    const [enableLoadingAnimation, setEnableLoadingAnimation] = useState(false);
    const mounted = useRef(false);

    const makeNegative = (value: number) => value * -1;

    const style: CSSProperties = {
        bottom: makeNegative(marginBottom ?? margin ?? 0),
        left: makeNegative(marginLeft ?? margin ?? 0),
        right: makeNegative(marginRight ?? margin ?? 0),
        top: makeNegative(marginTop ?? margin ?? 0)
    };

    useEffect(() => {
        if (!enableLoadingAnimation) {
            if (mounted.current) {
                setEnableLoadingAnimation(true);
            } else {
                mounted.current = true;
            }
        }
    }, [loading]);

    return (
        <div className={`loader ${dark ? 'dark' : ''}`}>
            {children}
            <div className={`loading-overlay ${enableLoadingAnimation ? 'loading-animation' : ''} ${loading ? 'loading' : ''} ${transparent ? 'transparent' : ''}`} style={style}>
                <svg className='loader' viewBox='0 0 50 50'>
                    <circle cx='25' cy='25' r='20' />
                </svg>
            </div>
        </div>
    );
};

export default Loader;
