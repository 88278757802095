import { ReactText } from 'react';
import { Reducer } from 'redux';
import { Snackbar } from '../components/common/Snackbars';

export interface State {
    snackbars: Snackbar[];
}

interface AddSnackbarAction {
    type: 'ADD_SNACKBAR';
    snackbar: Omit<Snackbar, 'id'>;
}

interface RemoveSnackbarAction {
    type: 'REMOVE_SNACKBAR';
    id: ReactText;
}

type KnownAction = AddSnackbarAction | RemoveSnackbarAction;

export const actionCreators = {
    addSnackbar: (snackbar: Omit<Snackbar, 'id'>): AddSnackbarAction => ({ type: 'ADD_SNACKBAR', snackbar }),
    removeSnackbar: (id: ReactText): RemoveSnackbarAction => ({ type: 'REMOVE_SNACKBAR', id })
};

export const reducer: Reducer<State, KnownAction> = (state, action) => {
    if (state === undefined) {
        return { snackbars: [] };
    }

    switch (action.type) {
        case 'ADD_SNACKBAR':
            return { snackbars: [...state.snackbars, { ...action.snackbar, id: new Date().getTime() }] };
        case 'REMOVE_SNACKBAR':
            return { snackbars: state.snackbars.filter(x => x.id !== action.id) };
        default:
            return state;
    }
};
