import * as Banners from './Banners';
import * as Layout from './Layout';
import * as Snackbars from './Snackbars';
import * as Translation from './Translation';
import * as Selector from './Selector';

export interface ApplicationState {
    banners: Banners.State;
    layout: Layout.State;
    snackbars: Snackbars.State;
    translation: Translation.State;
    selector: Selector.State;
}

export const reducers = {
    banners: Banners.reducer,
    layout: Layout.reducer,
    snackbars: Snackbars.reducer,
    translation: Translation.reducer,
    selector: Selector.reducer
};

export const actionCreators = {
    ...Banners.actionCreators,
    ...Layout.actionCreators,
    ...Snackbars.actionCreators,
    ...Translation.actionCreators,
    ...Selector.actionCreators
};
