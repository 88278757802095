import React, { CSSProperties, ReactNode } from 'react';

export enum SectionColor {
    DeepBlue,
    LightSand,
    White
}

interface SectionProps {
    color: SectionColor;
    children?: ReactNode;
    style?: CSSProperties;
}

const Section = ({ children, color, style }: SectionProps) => {
    const getClass = () => {
        switch (color) {
            case SectionColor.DeepBlue:
                return 'deep-blue';
            case SectionColor.LightSand:
                return 'light-sand';
            case SectionColor.White:
                return 'white';
        }
    };

    return (
        <div className={`section ${getClass()}`} style={style}>
            {children}
        </div>
    );
};

export default Section;
