import { ReactText } from 'react';
import { Reducer } from 'redux';
import { Banner } from '../components/common/Banners';

export interface State {
    banners: Banner[];
}

interface AddBannerAction {
    type: 'ADD_BANNER';
    banner: Omit<Banner, 'id'>;
}

interface RemoveBannerAction {
    type: 'REMOVE_BANNER';
    id: ReactText;
}

type KnownAction = AddBannerAction | RemoveBannerAction;

export const actionCreators = {
    addBanner: (banner: Omit<Banner, 'id'>): AddBannerAction => ({ type: 'ADD_BANNER', banner }),
    removeBanner: (id: ReactText): RemoveBannerAction => ({ type: 'REMOVE_BANNER', id })
};

export const reducer: Reducer<State, KnownAction> = (state, action) => {
    if (state === undefined) {
        return { banners: [] };
    }

    switch (action.type) {
        case 'ADD_BANNER':
            return { banners: [...state.banners, { ...action.banner, id: new Date().getTime() }] };
        case 'REMOVE_BANNER':
            return { banners: state.banners.filter(x => x.id !== action.id) };
        default:
            return state;
    }
};
